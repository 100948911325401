
























































































































































































































































































































































































































































































































@import '~@/assets/styles/helpers/_variables.scss';
@media print {
  .print{
    display:block;
  }
  .no-print{
    display: none;
  }
}
.container {
  overflow: hidden;
}

.tabela-valores{
  width: 84%;
  margin-top: 0px;
  overflow-x: auto;
}
.table {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding-left: 15px;
  &-labels {
    min-width: 290px !important;
    max-width: 290px !important;
    border: 1px solid #ccc;
    border-right: 1px solid #ccc;
    @media(max-width: 500px) {
      min-width: 225px !important;
      max-width: 225px !important;
    }
  }
}

%default {
  padding-left: 10px;
  font-size: 12px !important;
  color: #9797ad;
  @media(max-width: 500px) {
    font-size: 9px !important;
  }
}

.btnBuscar {
  margin-left: 16px;
  align-self: center;
  color: #FFFFFF;
  background-color: $colorPrimary !important;

  &:hover {
    color: #000000;
  }
}

.balance-item-column {
  font-size: 12px;
  justify-content: start;
  color: $colorAccent;
  &:nth-child(even) {
    background: #eeeeee ;
  }

  &.header {
    color: darkcyan;
    font-weight: bold;
    h3 {
      font-size: 14px !important;
      text-align: right;
      padding-right: 10px;
      width: 100%;
      text-transform: uppercase;

    }
  }
  &.border {
    border-bottom: 1px solid #ccc;
  }
  &-abc {
    &:nth-child(even) {
      background: #eeeeee !important;
    }
  }
  &.default {
    @extend %default;
  }
  &.saldo {
    background: #e2dddd !important;
    &-atual {
      background: #eeffdd;
    }
    &-total {
      color: #1cb4ff !important;
      &-bug {
        color: #1cb4ff !important;
        background: #eeeeee;
      }
    }
  }

  &-balance {
    color: $colorGreenDarken;
    font-size: 12px;
    text-transform: uppercase;
    border-bottom: 1px solid #ccc;
  }

  &-incoming {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    border-top: 1px solid #ccc;
  }

  &-outbound {
    color: red;
    font-size: 12px;
    text-transform: uppercase;
  }

  &-costing {
    color: #267ec3;
    font-size: 12px;
    text-transform: uppercase;
  }
  .panel-labels::v-deep {
    .v-expansion-panel:nth-child(even) {
      background: #eeeeee;
    }
     .v-expansion-panel-header {
      min-height: 5px;
      position: relative;
      text-align: left;
      align-items: flex-start;
      display: block;
      @extend %default;
      padding:  5px 5px;
    }
  
      .v-expansion-panel-content__wrap { // dentro
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
        font-size: 12px;
        color: #9797ad;
        padding: 0 !important;
        h4 {
          padding-left: 40px;
          width: 100%;
          text-align: center;
          border-bottom: 1px solid #ccc;
          &:nth-child(odd) {
            background: #eeeeee;
          }
        }
      }
    .v-expansion-panel-header {
      border-bottom: 1px solid #ccc;
      h4 {
        order: 2;
        text-align: left;
        padding-left: 20px;
        text-transform: uppercase;
      }

      h5 {
        text-align: left;
        padding-left: 20px;
      }

      .v-expansion-panel-header__icon {
        order: 1;
        position: absolute;
        left: 0;
        top: 15%;
      }
    }
  }

  .panel-labels-niveis::v-deep {     
  .v-expansion-panel { // 1 nivel
    .v-expansion-panel-header__icon {
      padding-left: 8px;
    }
    .v-expansion-panel-header {
      padding-left: 10px !important;
    }

    .v-expansion-panel-content > .v-expansion-panel-content__wrap h4 { // receita e custeio nivel 2
      &:nth-child(even) {
        background: #eeeeee;
      }
    }

      margin: 0;
      .v-expansion-panel-content .v-expansion-panel  { // 2 labels
        &:nth-child(even) {
           background: #eeeeee;
        }
      }
      .v-expansion-panel-content > .v-expansion-panel-content__wrap{
        .v-expansion-panel-content  > .v-expansion-panel-content__wrap{ // 3 labels
          h4{
            &:nth-child(odd) {
              background: white;
            }
          }
        }
      }
    }
    .v-expansion-panel-header {
        min-height: 20px;
        position: relative;
        text-align: left;
        align-items: flex-start;
        display: block;
        @extend %default;
        padding:  5px 5px;
        &.custeio {
          // background: #f9e2c0;
        }
    }
  
    .v-expansion-panel-content__wrap { // dentro
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      font-size: 12px;
      color: #9797ad;
      padding: 0 !important;
      border-bottom: 1px solid #ccc;
      h4 {
        padding-left: 40px;
      }
    }
    .v-expansion-panel-header {
      border-bottom: 1px solid #ccc;
      h4 {
        order: 2;
        text-align: left;
        padding-left: 20px;
        text-transform: uppercase;
      }

      h5 {
        text-align: left;
        padding-left: 20px;
      }

      .v-expansion-panel-header__icon {
        order: 1;
        position: absolute;
        left: 0;
        top: 15%;
      }
    }
  }

  .panel-dados::v-deep {
    .v-expansion-panel .v-expansion-panel-content .v-expansion-panel-content__wrap h4 { // receitas e custeio striped valores
      &:nth-child(even) {
        background: #eeeeee;
      }
    }

    .v-expansion-panel { // 2 nivel de valores
      margin: 0;
      border-bottom: 1px solid #ccc;
      &:nth-child(even) {
        background: #eeeeee;
      }
      .v-expansion-panel-content  > .v-expansion-panel-content__wrap{
        padding: 0;
        h4 {
          padding-right: 15px;
          width: 100%;
          text-align: right;
        }
        
          .v-expansion-panel-content  > .v-expansion-panel-content__wrap{
            h4{
              text-align: right;
              &:nth-child(odd) {
                background: white;
              }
            }
          }
      }
    }
    
     .v-expansion-panel-header {
      min-height: 20px;
      padding: 5px 0;
      position: relative;
      text-align: left;
      align-items: flex-start;
      display: block;
      font-size: 12px;
      .v-expansion-panel-header__icon {
        display: none;
      }
      &.custeio {
        // background: #f9e2c0;
      }
    }
    
    // Tabs interna
      .v-expansion-panel-content__wrap {
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
        padding-right: 0;
        color: #de890a;
        font-size: 12px;
        padding-bottom: 0;
        padding-left: 35px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        width: 100%;
        &:last-child {
          border-bottom:none;
        }
      }

    .v-expansion-panel-header {
      h4 {
        order: 2;
        text-align: left;
      }
    }
  }
}

@media print {
  .container, .container--fluid {
    min-width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .v-card__text {
    margin: 0 !important;
    padding: 0 !important;
  }
}
.titulo span {
  font-weight: bold;
  cursor: pointer;
}
