






































































@import '~@/assets/styles/helpers/_variables.scss';
.select-date {
  width: 100%;
  display: flex;
  // flex-flow: center;
  flex-wrap: nowrap;
  // justify-content: center;
  font-size:18px;
  &-title {
    color: #1cb4ff;
    font-size: 18px;
  }
  &-back,
  &-next {
    padding: 0 10px;
    cursor: pointer;
    &:focus::after{
      background: white;
    }
  }
}
